import { Svg, type SvgProps } from "./Svg";

export const WalletIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
	props,
) => {
	return (
		<Svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clip-path="url(#clip0_248_8258)">
				<path
					d="M14.8789 2.36664C15.432 2.36664 15.8789 2.81445 15.8789 3.36664C15.8789 3.91883 15.432 4.36664 14.8789 4.36664H3.37891C3.10266 4.36664 2.87891 4.59164 2.87891 4.86664C2.87891 5.14164 3.10266 5.36664 3.37891 5.36664H14.8789C15.982 5.36664 16.8789 6.26351 16.8789 7.36664V14.3666C16.8789 15.4698 15.982 16.3666 14.8789 16.3666H2.87891C1.77422 16.3666 0.878906 15.4698 0.878906 14.3666V4.36664C0.878906 3.26195 1.77422 2.36664 2.87891 2.36664H14.8789ZM13.8789 11.8666C14.432 11.8666 14.8789 11.4198 14.8789 10.8666C14.8789 10.3135 14.432 9.86664 13.8789 9.86664C13.3258 9.86664 12.8789 10.3135 12.8789 10.8666C12.8789 11.4198 13.3258 11.8666 13.8789 11.8666Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_248_8258">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(0.878906 0.366638)"
					/>
				</clipPath>
			</defs>
		</Svg>
	);
};
