import { isApeTheme } from "@/utils/theme";
import { Button, Typography } from "@vaporfi/uikit";
import { STRATOSPHERE_URL } from "@vaporfi/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const StratosphereIndicator = () => {
	return (
		<Link href={STRATOSPHERE_URL} legacyBehavior>
			<Button
				className={`${
					isApeTheme
						? "bg-white px-6 py-3 rounded-lg gap-2.5"
						: "bg-[#D6DADA] rounded-[18px] gap-1"
				}  flex justify-start`}
				data-testid="strat-indicator-btn"
				variant="squared"
			>
				<Image
					data-testid="strat-indicator-logo"
					src="/images/stratosphere/Basic-rounded.png"
					height={24}
					width={24}
					alt="Stratosphere tier logo"
				/>
				<Typography
					data-testid="strat-indicator-tier"
					color="black"
					weight="normal"
					size="sm"
				>
					Basic
				</Typography>
			</Button>
		</Link>
	);
};

export default StratosphereIndicator;
