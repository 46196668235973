import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
	client: {
		NEXT_PUBLIC_THEME: z.enum(["vapor", "ape"]).default("vapor"),
		NEXT_PUBLIC_API_URL: z
			.string()
			.url()
			.default("https://stg-vapordex-api.vaporfiengineering.com"),
		NEXT_PUBLIC_APP_URL: z
			.string()
			.url()
			.default("https://stg-vapordex-dapp.vaporfiengineering.com"),
		NEXT_PUBLIC_BATC_URL: z.string().url().default("https://batc.vaporfi.dev"),
		NEXT_PUBLIC_AVAILABLE_CHAINS: z
			.string()
			.min(1)
			.default("40,41,43113,43114"),
		NEXT_PUBLIC_CHAIN_ID: z.string().min(1),
		NEXT_PUBLIC_COINGECKO_API_KEY: z.string().optional(),
		NEXT_PUBLIC_ENVIRONMENT: z
			.enum(["development", "production", "staging"])
			.default("development"),
		NEXT_PUBLIC_MOCK_WALLET_KEY: z.string().optional(),
		NEXT_PUBLIC_ONRAMPER_API_KEY: z.string().optional(),
		NEXT_PUBLIC_POSTHOG_HOST: z
			.string()
			.url()
			.default("https://app.posthog.com"),
		NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
		NEXT_PUBLIC_SUPABASE_URL: z
			.string()
			.url()
			.default("https://xgysjndjcxgqgfzirxkx.supabase.co"),
		NEXT_PUBLIC_TOKEN_FACTORY_CHAINS: z.string().min(1).default("43113,43114"),
		NEXT_PUBLIC_UNSTOPPABLE_DOMAINS: z.string().min(1),
	},
	experimental__runtimeEnv: {
		NEXT_PUBLIC_BATC_URL: process.env.NEXT_PUBLIC_BATC_URL,
		NEXT_PUBLIC_THEME: process.env.NEXT_PUBLIC_THEME,
		NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
		NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
		NEXT_PUBLIC_AVAILABLE_CHAINS: process.env.NEXT_PUBLIC_AVAILABLE_CHAINS,
		NEXT_PUBLIC_CHAIN_ID: process.env.NEXT_PUBLIC_CHAIN_ID,
		NEXT_PUBLIC_COINGECKO_API_KEY: process.env.NEXT_PUBLIC_COINGECKO_API_KEY,
		NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
		NEXT_PUBLIC_MOCK_WALLET_KEY: process.env.NEXT_PUBLIC_MOCK_WALLET_KEY,
		NEXT_PUBLIC_ONRAMPER_API_KEY: process.env.NEXT_PUBLIC_ONRAMPER_API_KEY,
		NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
		NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
		NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
		NEXT_PUBLIC_TOKEN_FACTORY_CHAINS:
			process.env.NEXT_PUBLIC_TOKEN_FACTORY_CHAINS,
		NEXT_PUBLIC_UNSTOPPABLE_DOMAINS:
			process.env.NEXT_PUBLIC_UNSTOPPABLE_DOMAINS,
	},
	server: {
		AVALANCHE_PAID_RPC: z.string().url().optional(),
		COVALENT_API_KEY: z.string().min(1).optional(),
		DIRECTUS_STATIC_TOKEN: z.string().optional(),
		DIRECTUS_URL: z.string().url().optional(),
		EDGE_CONFIG: z.string().url().optional(),
		NODE_ENV: z.enum(["development", "production"]),
		SUPABASE_ANON_KEY: z.string().min(1).optional(),
	},
});
