import { isApeTheme } from "@/utils/theme";
import { Button, Typography } from "@vaporfi/uikit";
import { STRATOSPHERE_URL } from "@vaporfi/utils";
import Link from "next/link";
import React from "react";

const SaveRewards = () => {
	return (
		<Link href={STRATOSPHERE_URL} legacyBehavior>
			<Button
				fullWidth
				color={isApeTheme ? "white" : "turquoise"}
				className={isApeTheme ? "rounded-lg px-6 py-3 !h-11" : ""}
				size="base"
				variant={isApeTheme ? "squared" : undefined}
				css={{
					height: "var(--space-9)",
				}}
			>
				<Typography size="base" weight="semibold" color="black">
					Save & Earn Rewards
				</Typography>
			</Button>
		</Link>
	);
};

export default SaveRewards;
