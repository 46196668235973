import { isApeTheme } from "@/utils/theme";
import Sidebar from "@components/Sidebar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useMatchBreakpointsContext } from "@vapordex/uikit";

const Main = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
}));

const DefaultLayout = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	const { isMobile } = useMatchBreakpointsContext();

	return (
		<Box
			sx={{
				display: "flex",
				height: "100vh",
				width: "100vw",
			}}
			className={className}
		>
			<Sidebar />
			<Main
				as="main"
				sx={{
					overflowX: "hidden",
					padding: 0,
					background: isApeTheme ? "black" : undefined,
					width: "100%",
				}}
			>
				<Box
					width="100%"
					sx={{
						padding: 0,
						paddingBottom: isMobile ? "10vh" : 0,
					}}
				>
					{children}
				</Box>
			</Main>
		</Box>
	);
};

export default DefaultLayout;
