import { isApeTheme } from "@/utils/theme";
import useAuth from "@hooks/useAuth";
import { WalletConnectModal } from "@vaporfi/features";
import { useModal } from "@vaporfi/hooks";
import {
	Button,
	type ButtonProps,
	Typography,
	WalletIcon,
} from "@vaporfi/uikit";

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
	const { login } = useAuth();
	const [onPresentConnectModal] = useModal(
		<WalletConnectModal login={login} />,
	);

	return (
		<Button
			data-testid="connect-wallet-btn"
			onClick={onPresentConnectModal}
			color={isApeTheme ? "neutral" : "gradient"}
			size="base"
			className={isApeTheme ? "rounded-lg px-6 py-3 !h-11" : "h-10"}
			{...props}
		>
			{isApeTheme && <WalletIcon />}
			<Typography color="turquoise" weight="semibold">
				{children || "Connect Wallet"}
			</Typography>
		</Button>
	);
};

export default ConnectWalletButton;
