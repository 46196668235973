import type React from "react";
import { Image } from "../../../../components/Image";
import {
	RefreshIcon,
	WalletFilledIcon,
	WarningIcon,
} from "../../../../components/Svg";
import type { Colors } from "../../../../theme/types";
import { type Variant, variants } from "./types";

import styled from "styled-components";

const MenuIconWrapper = styled.div<{ borderColor: keyof Colors }>`
  align-items: center;
  // background-color: ${({ theme }) => theme.colors.background};
  // border-color: ${({ theme, borderColor }) => theme.colors[borderColor]};
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border: unset;
  display: flex;
  justify-content: center;
  height: calc(50% + 15px);
  width: 30px;
  position: absolute;
  left: 10px;
  top: 0px;
  z-index: 102;
`;

const ProfileIcon = styled(Image)`
  left: 0;
  position: absolute;
  top: -4px;
  z-index: 102;

  & > img {
    border-radius: 50%;
  }
`;

export const NoProfileMenuIcon: React.FC<
	React.PropsWithChildren<{ isApeTheme?: boolean }>
> = ({ isApeTheme }) => (
	//@ts-expect-error:abcd
	<MenuIconWrapper borderColor={isApeTheme ? "white" : "primary"}>
		<WalletFilledIcon color={isApeTheme ? "white" : "primary"} width="24px" />
	</MenuIconWrapper>
);

export const PendingMenuIcon: React.FC = () => (
	<MenuIconWrapper borderColor="secondary">
		<RefreshIcon color="secondary" height="24px" spin />
	</MenuIconWrapper>
);

export const WarningMenuIcon: React.FC = () => (
	<MenuIconWrapper borderColor="warning">
		<WarningIcon color="warning" width="24px" />
	</MenuIconWrapper>
);

export const DangerMenuIcon: React.FC = () => (
	<MenuIconWrapper borderColor="failure">
		<WarningIcon color="failure" width="24px" />
	</MenuIconWrapper>
);

const MenuIcon: React.FC<
	React.PropsWithChildren<{
		isApeTheme?: boolean;
		avatarSrc?: string;
		variant: Variant;
	}>
> = ({ avatarSrc, isApeTheme, variant }) => {
	if (variant === variants.DANGER) {
		return <DangerMenuIcon />;
	}

	if (variant === variants.WARNING) {
		return <WarningMenuIcon />;
	}

	if (variant === variants.PENDING) {
		return <PendingMenuIcon />;
	}

	if (!avatarSrc) {
		return <NoProfileMenuIcon isApeTheme={isApeTheme} />;
	}

	return <ProfileIcon src={avatarSrc} height={40} width={40} />;
};

export default MenuIcon;
