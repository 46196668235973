import { env } from "@/env";
import { isApeTheme } from "@/utils/theme";
import Header from "@components/Header";
import Page from "@components/Layout/Page";
import NetworkModal, {
	useNetworkSwitcher,
} from "@components/Menu/NetworkSelector/NetworkModal";
import useActiveWagmi from "@hooks/useActiveWagmi";
import { Chains } from "@vapordex/sdk";
import { useMatchBreakpointsContext } from "@vapordex/uikit";
import { useModal } from "@vaporfi/hooks";
import { Box, Button, Container, Typography } from "@vaporfi/uikit";
import { Refresh } from "@vaporfi/uikit";
import type { SupportedChainId } from "@vaporfi/utils";
import Image from "next/image";
import Link from "next/link";
import { type ReactNode, useCallback, useMemo } from "react";

const UnsupportedChain: React.FC<
	React.PropsWithChildren<{
		children: ReactNode;
		supportedChainIds: SupportedChainId[] | "all";
	}>
> = ({ children, supportedChainIds }) => {
	const { chain, isConnected } = useActiveWagmi();
	const chainId = chain?.id;
	const { handleSwitchNetwork: _handleWagmiSwitch } = useNetworkSwitcher();

	const [onPresentNetworkModal] = useModal(<NetworkModal />);

	const { isMonoChain, chainName, supportedChainID } = useMemo(() => {
		if (Array.isArray(supportedChainIds)) {
			const _chainId = supportedChainIds?.[0];
			return {
				isMonoChain: supportedChainIds?.length === 1,
				supportedChainID: _chainId,
				chainName: Chains[_chainId]?.name,
			};
		}
		return { isMonoChain: false, supportedChainID: null, chainName: "" };
	}, [supportedChainIds]);

	const handleSwitchNetwork = useCallback(() => {
		if (supportedChainIds !== "all" && isMonoChain) {
			return _handleWagmiSwitch(supportedChainID)().finally(() => {});
		}
		return onPresentNetworkModal();
	}, [
		supportedChainID,
		isMonoChain,
		supportedChainID,
		_handleWagmiSwitch,
		onPresentNetworkModal,
	]);

	const { isMobile } = useMatchBreakpointsContext();

	if (
		!supportedChainIds ||
		!isConnected ||
		supportedChainIds === "all" ||
		supportedChainIds?.includes(chainId)
	) {
		return children;
	}

	return (
		<>
			<Header
				breadcrumbs={[
					{
						currenciesLabel: "",
						page: "Unsupported Chain",
						path: "/unsupported-chain",
					},
				]}
			/>
			<Page>
				<Container stack="column" itemsCenter className="m-[30px]">
					<Box
						fullWidth
						className="flex flex-col items-center gap-4 p-[30px]"
						bg="black"
					>
						<Image src="/images/union.png" alt="Error" width={90} height={90} />

						<Typography
							className="font-manverse text-custom-linear-gradient"
							size="2xl"
						>
							CHAIN NOT SUPPORTED
						</Typography>

						{isApeTheme ? (
							<>
								<Link href={env.NEXT_PUBLIC_APP_URL}>
									<Typography color="turquoise">Visit VaporDEX</Typography>
								</Link>
							</>
						) : (
							<>
								<Typography size="sm">
									Please switch to a supported chain.
								</Typography>
								<Button
									color="blue"
									variant="filled"
									size="base"
									onClick={handleSwitchNetwork}
								>
									<Typography size="sm">
										{isMonoChain ? `Switch to ${chainName}` : "Switch Network"}
									</Typography>
									{!isMobile && <Refresh />}
								</Button>
							</>
						)}
					</Box>
				</Container>
			</Page>
		</>
	);
};

export default UnsupportedChain;
