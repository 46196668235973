import { Token } from "@vapordex/sdk";
import { serializeToken } from "state/user/hooks/helpers";
import { type SupportedChainId, TESTNET_CHAIN_IDS } from "./chains";
import { getChainIdCookie } from "./networks";
import type { SerializedToken } from "./types";

import { ChainId } from "@vapordex/sdk";

const {
	APECHAIN,
	AVALANCHE,
	AVALANCHE_TESTNET,
	CURTIS,
	SKALE_EUROPA_TESTNET,
	TELOS,
	TELOS_TESTNET,
} = ChainId;

interface TokenList {
	[symbol: string]: Token;
}

export const defineTokens = <T extends TokenList>(t: T) => t;

export const mainnetTokens = {
	[APECHAIN]: defineTokens({
		apeUSD: new Token(
			APECHAIN,
			"0xA2235d059F80e176D931Ef76b6C51953Eb3fBEf4",
			18,
			"ApeUSD",
			"Ape USD",
		),
		apeETH: new Token(
			APECHAIN,
			"0xcF800F4948D16F23333508191B1B1591daF70438",
			18,
			"ApeETH",
			"Ape ETH",
		),
		wape: new Token(
			APECHAIN,
			"0x48b62137edfa95a428d35c09e44256a739f6b557",
			18,
			"WAPE",
			"Wrapped ApeCoin",
		),
	}),
	[AVALANCHE]: defineTokens({
		// avax here points to the wavax contract. Wherever the currency AVAX is required, conditional checks for the symbol 'AVAX' can be used
		avax: new Token(
			AVALANCHE,
			"0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
			18,
			"AVAX",
			"Avalanche",
		),
		euroc: new Token(
			AVALANCHE,
			"0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD",
			6,
			"EUROC",
			"Euro Coin",
		),
		fld: new Token(
			AVALANCHE,
			"0x88F89BE3E9b1dc1C5F208696fb9cABfcc684bD5F",
			18,
			"FLD",
			"Fold",
		),
		usdc: new Token(
			AVALANCHE,
			"0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
			6,
			"USDC",
			"USD Coin",
		),
		usdc_e: new Token(
			AVALANCHE,
			"0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
			6,
			"USDC.e",
			"USD Coin",
		),
		usdt: new Token(
			AVALANCHE,
			"0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
			6,
			"USDT",
			"TetherToken",
		),
		vape: new Token(
			AVALANCHE,
			"0x7bddaF6DbAB30224AA2116c4291521C7a60D5f55",
			18,
			"VAPE",
			"VapeToken",
		),
		vapeV2: new Token(
			AVALANCHE,
			"0x7CF5d950528e73F25995e7442Dd01d9457496AB2",
			18,
			"VAPE",
			"VapeToken",
		),
		vpnd: new Token(
			AVALANCHE,
			"0x83a283641C6B4DF383BCDDf807193284C84c5342",
			18,
			"VPND",
			"VaporNodes",
		),
		wavax: new Token(
			AVALANCHE,
			"0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
			18,
			"WAVAX",
			"Wrapped AVAX",
		),
		wbtc: new Token(
			AVALANCHE,
			"0x7CF5d950528e73F25995e7442Dd01d9457496AB2",
			18,
			"WBTC",
			"Wrapped Bitcoin",
		),
	}),
	[TELOS]: defineTokens({
		telos: new Token(
			TELOS,
			"0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E",
			18,
			"TLOS",
			"Telos",
		),
		usdc: new Token(
			TELOS,
			"0x8D97Cea50351Fb4329d591682b148D43a0C3611b",
			6,
			"USDC",
			"USD Coin",
		),
		usdt: new Token(
			TELOS,
			"0x975Ed13fa16857E83e7C493C7741D556eaaD4A3f",
			6,
			"USDT",
			"USD Tether",
		),
		wtelos: new Token(
			TELOS,
			"0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E",
			18,
			"WTLOS",
			"Wrapped TLOS",
		),
	}),
};
export const testnetTokens = {
	[AVALANCHE_TESTNET]: defineTokens({
		cake: new Token(
			AVALANCHE_TESTNET,
			"0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe",
			18,
			"CAKE",
			"AVAX",
		),
		usdc: new Token(
			AVALANCHE_TESTNET,
			"0xeA42E3030ab1406a0b6aAd077Caa927673a2c302",
			6,
			"USDC",
			"USD Coin",
		),
		vape: new Token(
			AVALANCHE_TESTNET,
			"0xbA3136bE37807f46849a549a1733178A7A25803F",
			18,
			"VAPE",
			"VapeToken",
		),
		vpnd: new Token(
			AVALANCHE_TESTNET,
			"0x096F22B7891DeA0e9340365Be2021eEa562D0b55",
			18,
			"VPND",
			"VaporNodes",
		),
		wavax: new Token(
			AVALANCHE_TESTNET,
			"0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
			18,
			"WAVAX",
			"Wrapped AVAX",
		),
	} as const),

	[CURTIS]: defineTokens({
		usdc: new Token(
			CURTIS,
			"0xAdC76917E1c4a610Cfe409Dcde572EA5436d5bBE",
			18,
			"USDC",
			"USDC",
		),
		usdt: new Token(
			CURTIS,
			"0x5C83918e7f293782b7f83399E74F0eD054095eCe",
			18,
			"USDT",
			"USDT",
		),
	}),

	[SKALE_EUROPA_TESTNET]: defineTokens({
		usdc: new Token(
			SKALE_EUROPA_TESTNET,
			"0x6CE77Fc7970F6984eF3E8748A3826972Ec409Fb9",
			6,
			"USDC",
			"USDC Coin",
		),
		usdp: new Token(
			SKALE_EUROPA_TESTNET,
			"0xbEE0FB0C095405A17c079Cd5C3cc89525e5A9a8C",
			18,
			"USDP",
			"Pax Dollar",
		),
	}),
	[TELOS_TESTNET]: defineTokens({
		wtelos: new Token(
			TELOS_TESTNET,
			"0xaE85Bf723A9e74d6c663dd226996AC1b8d075AA9",
			18,
			"WTLOS",
			"Wrapped Telos",
		),
	}),
};

const tokens = (chainId?: number) => {
	const validChainId = chainId || Number.parseInt(getChainIdCookie(), 10);
	// If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
	if ([TESTNET_CHAIN_IDS].includes(validChainId as any)) {
		return testnetTokens[validChainId];
	}

	return mainnetTokens[validChainId];
};

export const DEFAULT_STABLE_COIN: { [chainId in SupportedChainId]: Token } = {
	[AVALANCHE]: mainnetTokens[AVALANCHE].usdc,
	[AVALANCHE_TESTNET]: testnetTokens[AVALANCHE_TESTNET].usdc,
	[APECHAIN]: mainnetTokens[APECHAIN].apeUSD,
	[CURTIS]: testnetTokens[CURTIS].usdc,
	[SKALE_EUROPA_TESTNET]: testnetTokens[SKALE_EUROPA_TESTNET].usdc,
	[TELOS]: mainnetTokens[TELOS].usdt,
	[TELOS_TESTNET]: testnetTokens[TELOS_TESTNET].wtelos, //wtlos for now, will deploy usdc or usdt later
};

export const STABLE_COINS: { [chainId in SupportedChainId]: Token[] } = {
	[AVALANCHE]: [
		mainnetTokens[AVALANCHE].usdc,
		mainnetTokens[AVALANCHE].usdt,
		mainnetTokens[AVALANCHE].usdc_e,
	],
	[AVALANCHE_TESTNET]: [testnetTokens[AVALANCHE_TESTNET].usdc],
	[APECHAIN]: [mainnetTokens[APECHAIN].apeUSD],
	[CURTIS]: [testnetTokens[CURTIS].usdc, testnetTokens[CURTIS].usdt],
	[SKALE_EUROPA_TESTNET]: [testnetTokens[SKALE_EUROPA_TESTNET].usdc],
	[TELOS]: [mainnetTokens[TELOS].usdc, mainnetTokens[TELOS].usdt],
	[TELOS_TESTNET]: [testnetTokens[TELOS_TESTNET].wtelos],
};

const unserializedTokens = tokens();

type SerializedTokenList = Record<
	keyof typeof unserializedTokens,
	SerializedToken
>;

export const serializeTokens = () => {
	const serializedTokens = Object.keys(unserializedTokens).reduce(
		(accum, key) => {
			accum[key] = serializeToken(unserializedTokens[key]);
			return accum;
		},
		{} as SerializedTokenList,
	);

	return serializedTokens;
};

export default tokens;
